import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { Box, Grid, Typography, TextField, Hidden } from '@material-ui/core';
import { $usd } from 'components/utility/data';
import makeStyles from './CartItem.css';

const debounceTime = 1000; // 1 second

const CartItem = ({ lineItem }) => {

    const shopify = useShopifyBuy();
    
    const debounce = React.useRef(null);
    const quantityRef = React.useRef(null);

    // debounce quantity changes
    const handleChange = () => {
        if (typeof debounce.current === 'number') {
            clearTimeout(debounce.current);
        }
        debounce.current = setTimeout(updateQuantity, debounceTime);
    }

    const handleRemove = async () => {
        if (shopify.cart) {
            await shopify.cart.remove(lineItem.id);
        }
    }

    const updateQuantity = async () => {
        if (quantityRef.current && quantityRef.current.value !== lineItem.quantity) {
            await shopify.cart.update(lineItem.id, quantityRef.current.value);
        }
    }

    const classes = makeStyles();

    return (
        <li className={classes.CartItem}>
            <div className={classes.thumb}>
                <img src={lineItem.attrs.variant.image.src} />
            </div>
            <div className={classes.details}>
                <div className={classes.mainContent}>
                    <h4>{lineItem.attrs.title.value}</h4>
                    <a onClick={handleRemove}>Remove</a>
                </div>
                <div className={classes.options}>
                    <Typography component="span" variant="caption" className={classes.subtotal}>
                        {$usd(lineItem.quantity * lineItem.variant.priceV2.amount)}
                    </Typography>
                    <div className={classes.quantity}>
                        {/* Hide dynamic quantity field for FW2022 launch
                        <TextField
                            inputRef={quantityRef}
                            name="quantity"
                            type="number"
                            variant="outlined"
                            required
                            className={classes.quantityField}
                            size="small"
                            defaultValue={lineItem.quantity}
                            onChange={handleChange}
                            InputProps={{ type: 'number', step: 1 }}
                            disabled={shopify.pending}
                        />
                        */}

                        {/* Show static item quantity for FW2022 launch */}
                        <span>{lineItem.quantity}</span>
                        <Hidden xsDown>
                            <Typography component="span" variant="caption">
                                <Box component="span" color="primary.main" mr={.5}>x</Box>
                                {$usd(lineItem.variant.priceV2.amount)}
                            </Typography>
                        </Hidden>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default CartItem;