import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { Box } from '@material-ui/core';
import CartItem from 'components/shop/CartItem';
import FormButton from 'components/forms/FormButton';
import { $usd } from 'components/utility/data';
import makeStyles from './ShoppingCart.css';

const ShoppingCart = () => {

    const shopify = useShopifyBuy();
    const lineItems = _.get(shopify, 'checkout.lineItems', []);
    
    const classes = makeStyles();

    return (
        <div className={classes.ShoppingCart}>
            {shopify.mounted && (<>
                {lineItems.length > 0 ? (<>
                    <Box component="ul" className={classes.cartList}>
                        {lineItems.map(item => <CartItem lineItem={item} key={item.id} />)}
                    </Box>
                    <div className={classes.checkoutControl}>
                        <Box display="block" textAlign="left">
                            <Link to="/shop">
                                <Box
                                    component="a"
                                    display="block"
                                    textAlign="left"
                                    fontSize={16}
                                >
                                    Continue shopping →
                                </Box>
                            </Link>
                        </Box>
                        <Box display="block" textAlign="right">
                            <Box component="span" display="inline" fontSize={22}>
                                <Box component="span" color="primary.main" fontSize={14}>
                                    TOTAL
                                </Box>
                                {` `}
                                {$usd(shopify.checkout.totalPriceV2.amount)}
                            </Box>
                        </Box>
                        <div>
                            <a href={shopify.pending || !shopify.checkout.ready ? '' : shopify.checkout.webUrl}>
                                <FormButton disabled={shopify.pending}>
                                    {shopify.pending ? 'Processing...' : 'Checkout'}
                                </FormButton>
                            </a>
                        </div>
                    </div>
                </>) : (
                    <Box py={2} fontSize={16}>
                        You have no items in your cart.
                        {` `}
                        <Link to="/shop">Visit the shop →</Link>
                    </Box>
                )}
            </>)}
        </div>
    );
}

export default ShoppingCart;