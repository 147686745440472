import React from 'react';
import { Link, navigate } from 'gatsby';
import { shopIsOnline } from 'components/context/ShopStatus';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import { Box } from '@material-ui/core';
import ShoppingCart from 'components/shop/ShoppingCart';

const ShopCartPage = () => {

    // redirect to shop if shop status is not valid
    if (!shopIsOnline() && typeof window !== 'undefined') {
        navigate("/shop");
    }

    return (
        <Layout containerSize="md">
            <PageTitle navOffset>
                <Box textAlign="left" mt={8} display="block" fontSize={20}>
                    <Link to="/shop">
                        <a>Shop</a>
                    </Link>
                    <Box component="span" color="primary.main">
                        {` /`} Your Cart
                    </Box>
                </Box>
            </PageTitle>
            <ShoppingCart />
        </Layout>
    );
}

export default ShopCartPage;