import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        CartItem: {
            display: 'block',
            padding: theme.spacing(3,0),
            margin: 0,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            position: 'relative',
        },
        thumb: {
            height: 80,
            width: 80,
            '& img': {
                height: 'inherit',
                width: 'inherit',
                objectFit: 'cover',
            },
            [theme.breakpoints.up('sm')]: {
                height: 120,
                width: 120,
            },
        },
        details: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(0,0,0,2),
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(4),
                flexDirection: 'row',
            },
        },
        mainContent: () => {
            const titleSize = 18;
            const textSize = 14;
            return {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexGrow: 1,
                '& h4': {
                    fontSize: titleSize,
                    fontWeight: 'normal',
                    margin: 0,
                    lineHeight: 1,
                },
                '& > a': {
                    fontSize: textSize,
                    lineHeight: 1,
                    cursor: 'pointer',
                },
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(1,0),
                }
            }
        },
        options: {
            fontSize: 14,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1,0,0),
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
                alignItems: 'center',
                '& > *': {
                    padding: theme.spacing(0,0,0,8),
                }
            }
        },
        quantity: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: theme.palette.primary.dark,
            '& > *': {
                margin: theme.spacing(0,0,0,1),
            },
        },
        quantityField: {
            fontSize: 14,
            width: '5em',
            [theme.breakpoints.up('sm')]: {
                fontSize: 16,
            },
            '& *': {
                fontSize: 'inherit',
            },
            '& input.MuiInputBase-input': {
                padding: '.5em .5em .5em 1em',
            }
        },
        subtotal: {
            fontSize: 18,
            textAlign: 'right',
            lineHeight: 1,
            [theme.breakpoints.up('md')]: {
                width: 180,
            }
        }
    };
});