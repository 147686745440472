import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        ShoppingCart: {
            margin: theme.spacing(1, 0),
            borderTop: `1px solid ${theme.palette.primary.main}`,
            '& a': {
                color: theme.palette.primary.dark,
                '&:hover': {
                    color: theme.palette.primary.light,
                }
            }
        },
        cartList: {
            display: 'block',
            padding: 0,
            margin: 0,
            maxWidth: '100%',
        },
        checkoutControl: {
            '& > *': {
                display: 'block',
                textAlign: 'right',
                margin: theme.spacing(2, 0),
            }
        }
    };
});