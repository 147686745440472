import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './title.css';

const PageTitle = (props) => {

    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
}

PageTitle.propTypes = {
    navOffset: PropTypes.bool,
}

PageTitle.defaultProps = {
    navOffset: false,
}

export default PageTitle;