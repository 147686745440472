export const sortByPath = function (a, b) {
    if (a.relativePath < b.relativePath) return -1;
    if (a.relativePath > b.relativePath) return 1;
    return 0;
}

export const getMuiThemeColor = function(theme, keyString) {
    const keys = keyString.split('.');
    
    // default color key is main
    if (keys.length < 2) {
        keys.push('main');
    }

    return theme.palette[keys[0]][keys[1]];
}

export const getNodeFromQuery = function(queryResult, sourceName) {
    const useEdges = queryResult.hasOwnProperty('edges');
    const nodes = useEdges ? queryResult.edges.map(edge => edge.node) : queryResult.nodes;
    return nodes.find(n => n.name === sourceName);
}

export const $usd = function(value){
    return '$' + parseFloat(value).toFixed(2);
}