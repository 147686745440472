import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        textAlign: 'center',
        padding: `${theme.spacing(2)}px 0`,
        paddingTop: props => props.navOffset ? theme.components.navbar.height : 0,
        textTransform: 'uppercase',
        letterSpacing: '.1em',
    }
}))